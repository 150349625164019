import axios from 'axios'
import qs from 'qs'
import local from '@/utils/local'
// import {
//     Message
// } from "element-ui"
import domMessage from './messageOnce'
const messageOnce = new domMessage()
import router from '@/router'
const baseURL = 'https://admin.luckiestball.com'
// const baseURL = 'http://dianying.xxzcloud.com'
const baseApi = 'https://admin.luckiestball.com/api/'
// 设置请求拦截器
axios.interceptors.request.use((config) => {
    let token = localStorage.getItem('token')
    if (token) {
        config.headers.token = token
    }
    return config
}, err => {
    return Promise.reject(err)
})



// 设置响应拦截器
axios.interceptors.response.use((config) => {
    return config;
}, (res) => {

    if (res.response.data.code == 401) {
        // 退出到登录页面
        console.log('请登录', res.response.data.msg);
        // Message({
        //     message: res.response.data.msg,
        //     type: 'error'
        // });
        messageOnce.error({
            message:'你尚未注册/登录，请注册/登录后观看',
            type: 'error'
        })
        setTimeout(() => {
            router.push('/login')
            local.clear()
            setTimeout(() => {
                this.$router.go(0);
            }, 600);
        }, 600);
        // 清空本地存储中错误的tk
    }
    // else if (res.response.data.code == 500) {

    // }
    return Promise.reject(res.response.data)

})


export default {
    // get的封装
    get(url, params) {
        return new Promise((resolve, reject) => {
            axios.get(baseApi + url, {
                params
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // post的封装
    post(url, params) {
        return new Promise((resolve, reject) => {
            axios.post(baseApi + url, qs.stringify(params)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },
    // post的封装
    payurl(url, params) {
        return new Promise((resolve, reject) => {
            axios.post(url, qs.stringify(params)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },
    // post的封装
    Img(url, params) {
        return new Promise((resolve, reject) => {
            axios.post(baseApi + url, params).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    baseURL: baseURL,
}